@import './fonts.css';
@import './fixes.css';
@import './components.css';
@import './fonts-fix.css';
@import './forms.css';
@import './legacy.css';
@import './layout-fix.css';
@import './form-elements-fix.css';
@import './text-fix.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: 'America-Regular';
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
}

button:focus {
  outline: none;
  border: none;
}

.markdown ul {
  list-style-type: disc;
  padding-left: 20px;
}

.markdown a {
  color: #b3f340;
}
