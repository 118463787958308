/* Fixes para problemas de estilo después de la actualización a Tailwind CSS v3 */

/* Restablecer algunos estilos base */
html, body {
  font-size: 16px;
  line-height: 1.5;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* Corregir problemas de tamaño */
.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 714px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1280px;
  }
}

/* Corregir problemas de espaciado */
.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

/* Corregir problemas de flexbox */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

/* Corregir problemas de texto */
.text-center {
  text-align: center;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.font-bold {
  font-weight: bold;
}

/* Corregir problemas de fondo */
.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff;
}

.bg-green {
  background-color: #b3f340;
}

.bg-lime {
  background-color: #C2F061;
}

/* Corregir problemas de borde */
.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-5xl {
  border-radius: 2.5rem;
}

/* Corregir problemas de posicionamiento */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

@media (min-width: 1024px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }
}