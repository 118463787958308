/* Estilos para restaurar el diseño original */

/* Restablecer el tamaño de fuente base */
html {
  font-size: 16px;
}

/* Restablecer el tamaño del contenedor */
body {
  margin: 0;
  padding: 0;
  font-family: 'America-Regular', sans-serif;
  background-color: #161616;
  color: #ffffff;
  line-height: 1.5;
  overflow-x: hidden;
}

/* Restablecer el diseño del contenedor */
.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

@media (min-width: 1024px) {
  .md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
}

/* Restablecer los tamaños de texto */
.text-xs {
  font-size: 0.5rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-md {
  font-size: 1.25rem !important;
}

.text-lg {
  font-size: 1.5rem !important;
}

.text-xl {
  font-size: 1.75rem !important;
}

.text-2xl {
  font-size: 2rem !important;
}

.text-3xl {
  font-size: 2.5rem !important;
}

.text-4xl {
  font-size: 2.75rem !important;
}

/* Restablecer los colores */
.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #000000 !important;
}

.text-green {
  color: #b3f340 !important;
}

.bg-darkGray {
  background-color: #161616 !important;
}

.bg-green {
  background-color: #b3f340 !important;
}

.bg-lime {
  background-color: #C2F061 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

/* Restablecer los bordes */
.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-5xl {
  border-radius: 2.5rem !important;
}

/* Restablecer los márgenes */
.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

/* Restablecer los gaps */
.gap-3 {
  gap: 0.75rem !important;
}

.gap-y-6 {
  row-gap: 1.5rem !important;
}

.gap-y-10 {
  row-gap: 2.5rem !important;
}

/* Restablecer los anchos */
.w-5 {
  width: 1.25rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-14 {
  width: 3.5rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-max {
  width: max-content !important;
}

/* Restablecer los displays */
.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

@media (min-width: 1024px) {
  .md\:block {
    display: block !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:max-w-xl {
    max-width: 36rem !important;
  }
}

/* Restablecer los posicionamientos */
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.top-20 {
  top: 5rem !important;
}

.top-50vh {
  top: 50vh !important;
}

.top-60vh {
  top: 60vh !important;
}

.right-0 {
  right: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-20 {
  bottom: 5rem !important;
}

.left-20 {
  left: 5rem !important;
}

/* Restablecer los paddings */
.p-6 {
  padding: 1.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

/* Restablecer las fuentes */
.font-americaBlack {
  font-family: 'America-Black', sans-serif !important;
}

.font-americaBold {
  font-family: 'America-Bold', sans-serif !important;
}

.font-americaMedium {
  font-family: 'America-Medium', sans-serif !important;
}

.font-americaRegular {
  font-family: 'America-Regular', sans-serif !important;
}

.font-americaLight {
  font-family: 'America-Light', sans-serif !important;
}

.font-americaUltraLight {
  font-family: 'America-Ultralight', sans-serif !important;
}

.font-americaThin {
  font-family: 'America-Thin', sans-serif !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

/* Restablecer los botones */
button, .button {
  cursor: pointer !important;
  display: inline-block !important;
  text-align: center !important;
}

button:focus, .button:focus {
  outline: none !important;
  border: none !important;
}

/* Restablecer los enlaces */
a {
  color: inherit !important;
  text-decoration: none !important;
}

/* Restablecer los textos */
.text-center {
  text-align: center !important;
}

.leading-none {
  line-height: 1 !important;
}

/* Restablecer los cursores */
.cursor-pointer {
  cursor: pointer !important;
}

/* Restablecer las opacidades */
.opacity-20 {
  opacity: 0.2 !important;
}

/* Restablecer los bordes */
.border {
  border-width: 1px !important;
}

.border-green {
  border-color: #b3f340 !important;
}

.border-dashed {
  border-style: dashed !important;
}

/* Restablecer las alturas */
.h-px {
  height: 1px !important;
}

/* Estilos específicos para componentes */
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

/* Estilos para el componente QR */
.qr-code {
  background-color: #ffffff !important;
  padding: 1.5rem !important;
  border-radius: 2.5rem !important;
}

/* Estilos para los botones de descarga */
.download-button {
  display: block !important;
  width: 100% !important;
  padding: 1rem 0 !important;
  text-align: center !important;
  background-color: #b3f340 !important;
  color: #000000 !important;
  border-radius: 0.75rem !important;
  font-weight: 700 !important;
  margin-top: 1.5rem !important;
}

/* Estilos para los iconos */
.icon {
  display: inline-block !important;
  vertical-align: middle !important;
}

/* Estilos para los enlaces de descarga */
.download-link {
  display: block !important;
  width: 100% !important;
}

/* Estilos para las imágenes */
img {
  max-width: 100% !important;
  height: auto !important;
}

/* Estilos para los contenedores de flex */
.flex-1 {
  flex: 1 1 0% !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 1024px) {
  .md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
}