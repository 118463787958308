/* Estilos específicos para formularios */

/* Botones */
button, .button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

button:focus, .button:focus {
  outline: none;
  border: none;
}

/* Inputs */
input, textarea, select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #242424;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #4A535D;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:focus, textarea:focus, select:focus {
  outline: 0;
  border-color: #b3f340;
  box-shadow: 0 0 0 0.2rem rgba(179, 243, 64, 0.25);
}

/* Checkbox y Radio */
input[type="checkbox"], input[type="radio"] {
  width: auto;
  display: inline-block;
  margin-right: 0.5rem;
}

/* Labels */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* Grupos de formulario */
.form-group {
  margin-bottom: 1rem;
}

/* Botones específicos */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  color: #000000;
  background-color: #b3f340;
  border-color: #b3f340;
}

.btn-primary:hover {
  color: #000000;
  background-color: #a3e330;
  border-color: #a3e330;
}

.btn-secondary {
  color: #ffffff;
  background-color: #242424;
  border-color: #242424;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #161616;
  border-color: #161616;
}

/* Tamaños de botones */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* Botones de ancho completo */
.btn-block {
  display: block;
  width: 100%;
}