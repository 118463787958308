/* Correcciones específicas para los componentes de texto */

/* Correcciones para los encabezados */
h1 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 2.5rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

h2 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

h3 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 1.75rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

h4 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

h5 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 1.25rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

h6 {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

/* Correcciones para los párrafos */
p {
  font-family: 'America-Regular', sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

/* Correcciones para los enlaces */
a {
  color: #b3f340 !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
}

a:hover {
  text-decoration: underline !important;
}

/* Correcciones para las listas */
ul, ol {
  margin-bottom: 1rem !important;
  padding-left: 1.5rem !important;
  color: #ffffff !important;
}

li {
  margin-bottom: 0.5rem !important;
  font-family: 'America-Regular', sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #ffffff !important;
}

/* Correcciones para las citas */
blockquote {
  border-left: 4px solid #b3f340 !important;
  padding-left: 1rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 1rem !important;
  font-family: 'America-Light', sans-serif !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  color: #ffffff !important;
}

/* Correcciones para el código */
code {
  font-family: monospace !important;
  font-size: 0.875rem !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0.25rem 0.5rem !important;
  border-radius: 0.25rem !important;
  color: #ffffff !important;
}

pre {
  font-family: monospace !important;
  font-size: 0.875rem !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  margin-bottom: 1rem !important;
  overflow-x: auto !important;
  color: #ffffff !important;
}

pre code {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

/* Correcciones para las tablas */
table {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-bottom: 1rem !important;
  color: #ffffff !important;
}

th {
  font-family: 'America-Bold', sans-serif !important;
  font-size: 1rem !important;
  text-align: left !important;
  padding: 0.75rem !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2) !important;
  color: #ffffff !important;
}

td {
  font-family: 'America-Regular', sans-serif !important;
  font-size: 1rem !important;
  padding: 0.75rem !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #ffffff !important;
}

/* Correcciones para las clases de texto */
.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
}

.text-5xl {
  font-size: 3rem !important;
}

.text-6xl {
  font-size: 3.75rem !important;
}

.text-7xl {
  font-size: 4.5rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

.text-9xl {
  font-size: 8rem !important;
}

/* Correcciones para los pesos de fuente */
.font-thin {
  font-weight: 100 !important;
}

.font-extralight {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

/* Correcciones para la alineación de texto */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

/* Correcciones para la transformación de texto */
.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

/* Correcciones para el espaciado de letras */
.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

/* Correcciones para el espaciado de líneas */
.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-loose {
  line-height: 2 !important;
}

/* Correcciones para los colores de texto */
.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #000000 !important;
}

.text-green {
  color: #b3f340 !important;
}

.text-gray {
  color: #a0a0a0 !important;
}

.text-darkGray {
  color: #161616 !important;
}

/* Correcciones para los estilos de texto */
.italic {
  font-style: italic !important;
}

.not-italic {
  font-style: normal !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

/* Correcciones para el desbordamiento de texto */
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.overflow-clip {
  text-overflow: clip !important;
}

/* Correcciones para el espaciado de palabras */
.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-words {
  overflow-wrap: break-word !important;
}

.break-all {
  word-break: break-all !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.whitespace-pre-line {
  white-space: pre-line !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}