/* Correcciones específicas para los componentes principales */

/* Correcciones para el componente Container */
div[style*="backgroundImage"] {
  /* La imagen de fondo se carga desde el componente Container */
  /* La ruta correcta sería /assets/background.png */
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center !important;
}

/* Correcciones para el componente Head */
div.flex.items-center.justify-center.w-full.md\:w-1\/3 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

@media (min-width: 768px) {
  div.flex.items-center.justify-center.w-full.md\:w-1\/3 {
    width: 33.333333% !important;
  }
}

div.flex.items-center.justify-center.w-full.md\:w-1\/3 img.w-30\% {
  width: 30% !important;
}

div.flex.items-center.justify-center.w-full.md\:w-1\/3 div.ml-6.md\:ml-12.text-white {
  margin-left: 1.5rem !important;
  color: #ffffff !important;
}

@media (min-width: 768px) {
  div.flex.items-center.justify-center.w-full.md\:w-1\/3 div.ml-6.md\:ml-12.text-white {
    margin-left: 3rem !important;
  }
}

div.flex.items-center.justify-center.w-full.md\:w-1\/3 h1.text-2xl.md\:text-6xl.font-americaBold {
  font-size: 1.5rem !important;
  font-family: 'America-Bold', sans-serif !important;
}

@media (min-width: 768px) {
  div.flex.items-center.justify-center.w-full.md\:w-1\/3 h1.text-2xl.md\:text-6xl.font-americaBold {
    font-size: 3.75rem !important;
  }
}

/* Correcciones para el componente DownloadApps */
div.items-center.bg-white.justify-between.w-full.mb-10.md\:w-1\/2.flex-column.border-2.p-8.rounded-lg.border-white {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin-bottom: 2.5rem !important;
  background-color: #ffffff !important;
  border: 2px solid #ffffff !important;
  padding: 2rem !important;
  border-radius: 0.5rem !important;
}

@media (min-width: 768px) {
  div.items-center.bg-white.justify-between.w-full.mb-10.md\:w-1\/2.flex-column.border-2.p-8.rounded-lg.border-white {
    width: 50% !important;
  }
}

div.items-center.bg-white.justify-between.w-full.mb-10.md\:w-1\/2.flex-column.border-2.p-8.rounded-lg.border-white div.text-center {
  text-align: center !important;
}

div.items-center.bg-white.justify-between.w-full.mb-10.md\:w-1\/2.flex-column.border-2.p-8.rounded-lg.border-white p.text-black.text-xm.font-bold.font-americaLight.pb-5 {
  color: #000000 !important;
  font-weight: 700 !important;
  font-family: 'America-Light', sans-serif !important;
  padding-bottom: 1.25rem !important;
}

div.items-center.bg-white.justify-between.w-full.mb-10.md\:w-1\/2.flex-column.border-2.p-8.rounded-lg.border-white div.flex.items-center {
  display: flex !important;
  align-items: center !important;
}

/* Correcciones para el componente Button */
a.px-8.py-2.text-white.border.border-white.rounded-full.text-md.font-americaLight {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-radius: 9999px !important;
  font-size: 1rem !important;
  font-family: 'America-Light', sans-serif !important;
}

/* Correcciones para el componente PrivacyButtons */
div.md\:flex.items-center.justify-center {
  display: block !important;
}

@media (min-width: 768px) {
  div.md\:flex.items-center.justify-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

div.m-5.mt-16.md\:mt-24 {
  margin: 1.25rem !important;
  margin-top: 4rem !important;
}

@media (min-width: 768px) {
  div.m-5.mt-16.md\:mt-24 {
    margin-top: 6rem !important;
  }
}

div.text-center.m-5.mt-16.md\:mt-24 {
  text-align: center !important;
  margin: 1.25rem !important;
  margin-top: 4rem !important;
}

@media (min-width: 768px) {
  div.text-center.m-5.mt-16.md\:mt-24 {
    margin-top: 6rem !important;
  }
}

/* Correcciones para la pantalla Home */
div.w-full.mt-12.hidden.md\:flex.md\:items-stretch.md\:justify-between.md\:w-1\/2 {
  width: 100% !important;
  margin-top: 3rem !important;
  display: none !important;
}

@media (min-width: 768px) {
  div.w-full.mt-12.hidden.md\:flex.md\:items-stretch.md\:justify-between.md\:w-1\/2 {
    display: flex !important;
    align-items: stretch !important;
    justify-content: space-between !important;
    width: 50% !important;
  }
}

div.w-full.mt-12.hidden.md\:flex.md\:items-stretch.md\:justify-between.md\:w-1\/2 img.mb-12.md\:mb-0.md\:w-2\/5 {
  margin-bottom: 3rem !important;
}

@media (min-width: 768px) {
  div.w-full.mt-12.hidden.md\:flex.md\:items-stretch.md\:justify-between.md\:w-1\/2 img.mb-12.md\:mb-0.md\:w-2\/5 {
    margin-bottom: 0 !important;
    width: 40% !important;
  }
}

div.w-full.mt-12.hidden.md\:flex.md\:items-stretch.md\:justify-between.md\:w-1\/2 img.md\:w-2\/5 {
  width: 40% !important;
}

div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin-top: 6rem !important;
}

@media (min-width: 768px) {
  div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex {
    width: 33.333333% !important;
  }
}

div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex a.flex-1.mr-2.md\:mx-3 {
  flex: 1 1 0% !important;
  margin-right: 0.5rem !important;
}

@media (min-width: 768px) {
  div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex a.flex-1.mr-2.md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
}

div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex a.flex-1.ml-2.md\:mx-3 {
  flex: 1 1 0% !important;
  margin-left: 0.5rem !important;
}

@media (min-width: 768px) {
  div.items-center.justify-between.w-full.mt-24.md\:w-1\/3.flex a.flex-1.ml-2.md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
}

/* Correcciones para la pantalla Code */
div.text-white.text-center.mt-8.md\:mt-12.w-4\/5.md\:w-1\/2.hidden.md\:block {
  color: #ffffff !important;
  text-align: center !important;
  margin-top: 2rem !important;
  width: 80% !important;
  display: none !important;
}

@media (min-width: 768px) {
  div.text-white.text-center.mt-8.md\:mt-12.w-4\/5.md\:w-1\/2.hidden.md\:block {
    margin-top: 3rem !important;
    width: 50% !important;
    display: block !important;
  }
}

span.hidden.md\:block.text-white.font-bold.text-2xl {
  display: none !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
}

@media (min-width: 768px) {
  span.hidden.md\:block.text-white.font-bold.text-2xl {
    display: block !important;
  }
}

div.hidden.mt-12.md\:block {
  display: none !important;
  margin-top: 3rem !important;
}

@media (min-width: 768px) {
  div.hidden.mt-12.md\:block {
    display: block !important;
  }
}

div.p-6.bg-white.rounded-5xl {
  padding: 1.5rem !important;
  background-color: #ffffff !important;
  border-radius: 2.5rem !important;
}

div.mt-10.w-full.flex.justify-center {
  margin-top: 2.5rem !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

div.flex.flex-col.items-center.justify-between.md\:hidden {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
}

@media (min-width: 768px) {
  div.flex.flex-col.items-center.justify-between.md\:hidden {
    display: none !important;
  }
}

a.w-full.py-4.rounded-xl.bg-green.mt-6.block.text-center {
  width: 100% !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 0.75rem !important;
  background-color: #b3f340 !important;
  margin-top: 1.5rem !important;
  display: block !important;
  text-align: center !important;
}

span.text-base.text-black.font-americaBold {
  font-size: 1rem !important;
  color: #000000 !important;
  font-family: 'America-Bold', sans-serif !important;
}

button.flex.gap-3.w-full.items-center.justify-center.h-full.mt-6 {
  display: flex !important;
  gap: 0.75rem !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  margin-top: 1.5rem !important;
}

p.text-white.text-md.font-semibold {
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

div.text-green {
  color: #b3f340 !important;
}