/* Correcciones específicas para los componentes de formulario */

/* Correcciones para los inputs */
input {
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  border-radius: 0.75rem !important;
  color: #ffffff !important;
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  font-family: 'America-Regular', sans-serif !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
}

input:focus {
  outline: none !important;
  border-color: #b3f340 !important;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* Correcciones para los botones */
button.bg-green {
  background-color: #b3f340 !important;
  color: #000000 !important;
  border: none !important;
  border-radius: 0.75rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

button.bg-green:hover {
  background-color: #a3e330 !important;
}

button.bg-green:focus {
  outline: none !important;
}

button.border-green {
  background-color: transparent !important;
  color: #b3f340 !important;
  border: 1px solid #b3f340 !important;
  border-radius: 0.75rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

button.border-green:hover {
  background-color: rgba(179, 243, 64, 0.1) !important;
}

button.border-green:focus {
  outline: none !important;
}

/* Correcciones para los checkboxes */
input[type="checkbox"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  border: 1px solid #ffffff !important;
  border-radius: 0.25rem !important;
  margin-right: 0.5rem !important;
  position: relative !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}

input[type="checkbox"]:checked {
  background-color: #b3f340 !important;
  border-color: #b3f340 !important;
}

input[type="checkbox"]:checked::after {
  content: "✓" !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: #000000 !important;
  font-size: 0.75rem !important;
}

/* Correcciones para los selects */
select {
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  border-radius: 0.75rem !important;
  color: #ffffff !important;
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  font-family: 'America-Regular', sans-serif !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 1rem) center !important;
  padding-right: 2.5rem !important;
}

select:focus {
  outline: none !important;
  border-color: #b3f340 !important;
}

select option {
  background-color: #161616 !important;
  color: #ffffff !important;
}

/* Correcciones para los textareas */
textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  border-radius: 0.75rem !important;
  color: #ffffff !important;
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  font-family: 'America-Regular', sans-serif !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
  resize: vertical !important;
  min-height: 6rem !important;
}

textarea:focus {
  outline: none !important;
  border-color: #b3f340 !important;
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* Correcciones para los labels */
label {
  color: #ffffff !important;
  font-size: 1rem !important;
  font-family: 'America-Regular', sans-serif !important;
  margin-bottom: 0.5rem !important;
  display: block !important;
}

/* Correcciones para los mensajes de error */
.error-message {
  color: #ff4d4d !important;
  font-size: 0.875rem !important;
  font-family: 'America-Regular', sans-serif !important;
  margin-top: -0.5rem !important;
  margin-bottom: 1rem !important;
}

/* Correcciones para los grupos de formulario */
.form-group {
  margin-bottom: 1.5rem !important;
}

/* Correcciones para los botones de envío */
button[type="submit"] {
  background-color: #b3f340 !important;
  color: #000000 !important;
  border: none !important;
  border-radius: 0.75rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  width: 100% !important;
}

button[type="submit"]:hover {
  background-color: #a3e330 !important;
}

button[type="submit"]:focus {
  outline: none !important;
}

/* Correcciones para los botones de cancelar */
button[type="button"].cancel {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-radius: 0.75rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  width: 100% !important;
}

button[type="button"].cancel:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

button[type="button"].cancel:focus {
  outline: none !important;
}

/* Correcciones para los botones de acción */
button.action {
  background-color: transparent !important;
  color: #b3f340 !important;
  border: none !important;
  padding: 0 !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

button.action:hover {
  text-decoration: underline !important;
}

button.action:focus {
  outline: none !important;
}

/* Correcciones para los botones de icono */
button.icon-button {
  background-color: transparent !important;
  color: #ffffff !important;
  border: none !important;
  padding: 0.5rem !important;
  font-size: 1.5rem !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

button.icon-button:hover {
  color: #b3f340 !important;
}

button.icon-button:focus {
  outline: none !important;
}

/* Correcciones para los botones de enlace */
a.button {
  display: inline-block !important;
  background-color: #b3f340 !important;
  color: #000000 !important;
  border: none !important;
  border-radius: 0.75rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-family: 'America-Bold', sans-serif !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  text-align: center !important;
  text-decoration: none !important;
}

a.button:hover {
  background-color: #a3e330 !important;
}

a.button:focus {
  outline: none !important;
}

a.button.outline {
  background-color: transparent !important;
  color: #b3f340 !important;
  border: 1px solid #b3f340 !important;
}

a.button.outline:hover {
  background-color: rgba(179, 243, 64, 0.1) !important;
}

/* Correcciones para los botones de enlace secundarios */
a.button.secondary {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

a.button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}