/* Estilos específicos para componentes */

/* Container */
.container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 714px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1280px;
  }
}

/* Espaciado */
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 1024px) {
  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

/* Flexbox */
.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Tamaños */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

/* Márgenes */
.mt-6 {
  margin-top: 1.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

/* Gaps */
.gap-3 {
  gap: 0.75rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

/* Texto */
.text-base {
  font-size: 1rem;
}

.text-md {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 2.5rem;
}

.text-4xl {
  font-size: 2.75rem;
}

.leading-none {
  line-height: 1;
}

/* Fondos */
.bg-darkGray {
  background-color: #161616;
}

.bg-green {
  background-color: #b3f340;
}

.bg-lime {
  background-color: #C2F061;
}

/* Bordes */
.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-5xl {
  border-radius: 2.5rem;
}

/* Padding */
.p-6 {
  padding: 1.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

/* Ancho máximo */
.md\:max-w-xl {
  max-width: 36rem;
}

/* Posicionamiento */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-20 {
  top: 5rem;
}

.top-50vh {
  top: 50vh;
}

.top-60vh {
  top: 60vh;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-20 {
  bottom: 5rem;
}

.left-20 {
  left: 5rem;
}

/* Tamaños de ancho */
.w-5 {
  width: 1.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-max {
  width: max-content;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}

/* Opacidad */
.opacity-20 {
  opacity: 0.2;
}

/* Bordes */
.border {
  border-width: 1px;
}

.border-green {
  border-color: #b3f340;
}

.border-dashed {
  border-style: dashed;
}

/* Altura */
.h-px {
  height: 1px;
}