/* Estilos específicos para fuentes */

.font-americaBlack {
  font-family: 'America-Black', sans-serif;
}

.font-americaBold {
  font-family: 'America-Bold', sans-serif;
}

.font-americaMedium {
  font-family: 'America-Medium', sans-serif;
}

.font-americaRegular {
  font-family: 'America-Regular', sans-serif;
}

.font-americaLight {
  font-family: 'America-Light', sans-serif;
}

.font-americaUltraLight {
  font-family: 'America-Ultralight', sans-serif;
}

.font-americaThin {
  font-family: 'America-Thin', sans-serif;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

/* Tamaños de texto */
.text-xs {
  font-size: 0.5rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-base {
  font-size: 1rem;
}

.text-md {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.5rem;
}

.text-xl {
  font-size: 1.75rem;
}

.text-2xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 2.5rem;
}

.text-4xl {
  font-size: 2.75rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 3.25rem;
}

.text-7xl {
  font-size: 3.5rem;
}

.text-8xl {
  font-size: 3.75rem;
}

.text-9xl {
  font-size: 4rem;
}

/* Colores de texto */
.text-black {
  color: #000000;
}

.text-white {
  color: #ffffff;
}

.text-green {
  color: #b3f340;
}

.text-lime {
  color: #C2F061;
}

.text-darkGray {
  color: #161616;
}

.text-gray {
  color: #242424;
}

.text-lightGray {
  color: #4A535D;
}

/* Alineación de texto */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}